import React, { useState } from 'react';
import Button from '../Button';

import FormInputField from '../FormInputField/FormInputField';

import * as styles from './Contact.module.css';

const Contact = (props) => {
  const initialState = {
    name: '',
    phone: '',
    email: '',
    comment: '',
  };

  const [contactForm, setContactForm] = useState(initialState);

  const handleChange = (id, e) => {
    const tempForm = { ...contactForm, [id]: e };
    setContactForm(tempForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setContactForm(initialState);
  };

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <h4>Send Us A Message</h4>
        <p>
          Our Customer Service team are here for all enquiries Monday to Friday,
          9am - 5pm AEDT (Australian Eastern Daylight Savings Time).
        </p>
        <p>We look forward to hearing from you.</p>
      </div>

      <div className={styles.section}>
        <h4>Phone</h4>
        <p>+91 99400 97865</p>
        <p>+91 99400 59516</p>
        <p>+91 98400 32135</p>
      </div>

      <div className={styles.section}>
        <h4>Email</h4>
        <p>You can email us at "venkat@anujans.com" or "contact@anujans.com"</p>
      </div>

      <div className={styles.section}>
        <h4>Address</h4>
        <p>
          Anujans Maha Products 4/677,
          <br />
          Brindavanam, Kurinji Nagar,
          <br />
          Thomppampatti Post,
          <br />
          Coimbatore - 641 017
        </p>
      </div>
    </div>
  );
};

export default Contact;
